@font-face {
  font-family: 'adoba only';
  src: url(assets/fonts/adoba\ Only.ttf);
}

@font-face {
  font-family: 'DINPro';
  src: url(assets/fonts/DINPro.ttf);
}

@font-face {
  font-family: 'noto';
  src: url(assets/fonts/noto-sans-kr-v27-korean-regular.woff);
}